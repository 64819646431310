import {
  GetUserToFavoriteCandidates,
  ICandidate,
  IFilterUserToFavoriteCandidatesDto,
} from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { ICandidatesSlice } from '../candidates.slice';

interface IFilter {
  data: IFilterUserToFavoriteCandidatesDto;
}

interface IFilterResult {
  candidates: ICandidate[];
}

export const filterFavoritesCandidatesReducer = createAsyncThunk<IFilterResult, IFilter>(
  'candidatesSlice/filterFavoritesCandidatesReducer',
  async ({data}, {rejectWithValue}) => {
    try{
      const candidates = await GetUserToFavoriteCandidates(data);

      return {
        candidates: candidates ?? [],
      }; 
    }catch (error: any) {
      return rejectWithValue(error.message);
    }
  }, 
);

export const filterFavoritesCandidatesReducerPending = (state: ICandidatesSlice) => {
  state.isCandidatesLoading = true;
};

export const filterFavoritesCandidatesReducerFulfilled = (state: ICandidatesSlice, action: PayloadAction<IFilterResult>) => {
  state.candidates = action.payload.candidates;

  state.isCandidatesLoading = false;
};

export const filterFavoritesCandidatesReducerRejected = (state: ICandidatesSlice) => {
  state.isCandidatesLoading = false;
};
