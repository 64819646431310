import {
  GetUserSearchHistoriesCount,
  IFilterUserSearchHistoriesDto,
} from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { IUserSearchHistorySlice } from '../user-search-history.slice';

interface IFilter {
  data: IFilterUserSearchHistoriesDto;
}

interface IFilterResult {
  count: number;
}

export const filterUserSearchHistoriesCountReducer = createAsyncThunk<IFilterResult, IFilter>(
  'userSearchHistorySlice/filterUserSearchHistoriesCountReducer',
  async ({ data }, { rejectWithValue }) => {
    try {
      const count = await GetUserSearchHistoriesCount(data);

      return {
        count: count ?? 0,
      };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterUserSearchHistoriesCountReducerPending = (state: IUserSearchHistorySlice) => {
  state.isCountLoading = true;
};

export const filterUserSearchHistoriesCountReducerFulfilled = (state: IUserSearchHistorySlice, action: PayloadAction<IFilterResult>) => {
  state.count = action.payload.count;

  state.isCountLoading = false;
};

export const filterUserSearchHistoriesCountReducerRejected = (state: IUserSearchHistorySlice) => {
  state.isCountLoading = false;
};
