import { ICandidate } from '@EZenkof/common-recruiter-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  filterCandidatesReducer,
  filterCandidatesReducerFulfilled,
  filterCandidatesReducerPending,
  filterCandidatesReducerRejected,
} from './reducers/filter-candidates.reducer';
import {
  filterCandidatesCountReducer,
  filterCandidatesCountReducerFulfilled,
  filterCandidatesCountReducerPending,
  filterCandidatesCountReducerRejected,
} from './reducers/filter-candidates-count.reducer';
import {
  updateCandidateViewCardStatusReducer, updateCandidateViewCardStatusReducerFulfilled,
  updateCandidateViewCardStatusReducerPending, updateCandidateViewCardStatusReducerRejected,
} from './reducers/update-candidate-view-card-status.reducer';
import {
  filterFavoritesCandidatesReducer,
  filterFavoritesCandidatesReducerFulfilled,
  filterFavoritesCandidatesReducerPending, filterFavoritesCandidatesReducerRejected,
} from './reducers/filter-favorites-candidates.reducer';
import {
  filterFavoritesCandidatesCountReducer,
  filterFavoritesCandidatesCountReducerFulfilled,
  filterFavoritesCandidatesCountReducerPending, filterFavoritesCandidatesCountReducerRejected,
} from './reducers/filter-favorites-candidates-count.reducer';
import {
  updateCandidateFavoriteStatusReducer,
  updateCandidateFavoriteStatusReducerFulfilled,
  updateCandidateFavoriteStatusReducerPending, updateCandidateFavoriteStatusReducerRejected,
} from './reducers/update-candidate-favorite-status.reducer';
import {
  filterViewedCandidatesReducer,
  filterViewedCandidatesReducerFulfilled,
  filterViewedCandidatesReducerPending, filterViewedCandidatesReducerRejected,
} from './reducers/filter-viewed-candidates.reducer';
import {
  filterViewedCandidatesCountReducer,
  filterViewedCandidatesCountReducerFulfilled,
  filterViewedCandidatesCountReducerPending, filterViewedCandidatesCountReducerRejected,
} from './reducers/filter-viewed-candidates-count.reducer';

export interface ICandidatesSlice {
  candidates: ICandidate[];
  count: number;
  isCandidatesLoading: boolean;
  isCountLoading: boolean;
  isSearched: boolean;
  page: number;
}

const initialState: ICandidatesSlice = {
  candidates: [],
  count: 0,
  isCandidatesLoading: false,
  isCountLoading: false,
  isSearched: false,
  page: 0,
};

const CandidatesSlice = createSlice({
  name: 'candidatesSlice',
  initialState: initialState,
  reducers: {
    resetCandidates: (state) => {
      state.candidates = [];
      state.count = 0;
      state.page = 0;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setIsSearched(state, action: PayloadAction<boolean>) {
      state.isSearched = action.payload;
    },
  },
  extraReducers: function(builder) {
    builder
      .addCase(filterCandidatesReducer.pending, filterCandidatesReducerPending)
      .addCase(filterCandidatesReducer.fulfilled, filterCandidatesReducerFulfilled)
      .addCase(filterCandidatesReducer.rejected, filterCandidatesReducerRejected)

      .addCase(filterCandidatesCountReducer.pending, filterCandidatesCountReducerPending)
      .addCase(filterCandidatesCountReducer.fulfilled, filterCandidatesCountReducerFulfilled)
      .addCase(filterCandidatesCountReducer.rejected, filterCandidatesCountReducerRejected)

      .addCase(updateCandidateViewCardStatusReducer.pending, updateCandidateViewCardStatusReducerPending)
      .addCase(updateCandidateViewCardStatusReducer.fulfilled, updateCandidateViewCardStatusReducerFulfilled)
      .addCase(updateCandidateViewCardStatusReducer.rejected, updateCandidateViewCardStatusReducerRejected)

      .addCase(filterFavoritesCandidatesReducer.pending, filterFavoritesCandidatesReducerPending)
      .addCase(filterFavoritesCandidatesReducer.fulfilled, filterFavoritesCandidatesReducerFulfilled)
      .addCase(filterFavoritesCandidatesReducer.rejected, filterFavoritesCandidatesReducerRejected)

      .addCase(filterFavoritesCandidatesCountReducer.pending, filterFavoritesCandidatesCountReducerPending)
      .addCase(filterFavoritesCandidatesCountReducer.fulfilled, filterFavoritesCandidatesCountReducerFulfilled)
      .addCase(filterFavoritesCandidatesCountReducer.rejected, filterFavoritesCandidatesCountReducerRejected)

      .addCase(updateCandidateFavoriteStatusReducer.pending, updateCandidateFavoriteStatusReducerPending)
      .addCase(updateCandidateFavoriteStatusReducer.fulfilled, updateCandidateFavoriteStatusReducerFulfilled)
      .addCase(updateCandidateFavoriteStatusReducer.rejected, updateCandidateFavoriteStatusReducerRejected)

      .addCase(filterViewedCandidatesReducer.pending, filterViewedCandidatesReducerPending)
      .addCase(filterViewedCandidatesReducer.fulfilled, filterViewedCandidatesReducerFulfilled)
      .addCase(filterViewedCandidatesReducer.rejected, filterViewedCandidatesReducerRejected)

      .addCase(filterViewedCandidatesCountReducer.pending, filterViewedCandidatesCountReducerPending)
      .addCase(filterViewedCandidatesCountReducer.fulfilled, filterViewedCandidatesCountReducerFulfilled)
      .addCase(filterViewedCandidatesCountReducer.rejected, filterViewedCandidatesCountReducerRejected);
  },
});

export const {
  resetCandidates,
  setPage,
  setIsSearched,
} = CandidatesSlice.actions;

export default CandidatesSlice;
