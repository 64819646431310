import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useClickOutside from 'common/helper/hooks/use-click-outside.hook';
import { useTranslation } from 'react-i18next';

import QuestionSvg from '../../../svg/question.svg';
import DocsSvg from '../../../svg/docs.svg';

import styles from './styles.module.scss';

const HelpModal = () => {
  const [active, setActive] = useState<boolean>(false);

  const { t } = useTranslation();
  const location = useLocation();
  const ref = useRef(null);

  const navigateToWiki = () => window.open(`${process.env.REACT_APP_WIKI_URL}`, '_blank', 'noopener,noreferrer');

  const handleActive = (): void => setActive((s) => !s);

  useClickOutside(ref, handleActive, active);

  if (!location.pathname.includes('profile'))
    return null;

  return (
    <>
      {active && (
        <div className={styles.modal} ref={ref}>
          {/*todo временно*/}
          {/*<button className={styles.link} onClick={navigateToWiki}>*/}
          {/*  <DocsSvg />*/}
          {/*  <p>{t('popup.wiki')}</p>*/}
          {/*</button>*/}

          <a className={styles.link} href="https://t.me/hyranse_support" target="_blank" rel="noreferrer">
            <QuestionSvg />
            <p>{t('popup.help')}</p>
          </a>
        </div>
      )}

      <button
        onClick={handleActive}
        className={styles.container}
      >
        ?
      </button>
    </>
  );
};

export default HelpModal;
