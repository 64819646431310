import { combineReducers, configureStore } from '@reduxjs/toolkit';

import AvailableParseLimitSlice from './slices/available-parse-limit/available-parse-limit.slice';
import PaymentsSlice from './slices/payments/payments.slice';
import WorkspaceSlice from './slices/workspace/workspace.slice';
import CandidateModalSlice from './slices/candidate-modal/candidate-modal.slice';
import CandidatesSlice from './slices/candidates/candidates.slice';
import UserSearchHistorySlice from './slices/user-search-history/user-search-history.slice';

const rootReducer = combineReducers({
  userSearchHistorySlice: UserSearchHistorySlice.reducer,
  candidates: CandidatesSlice.reducer,
  availableParseLimit: AvailableParseLimitSlice.reducer,
  payments: PaymentsSlice.reducer,
  workspace: WorkspaceSlice.reducer,
  candidateModal: CandidateModalSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
