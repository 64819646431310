import React from 'react';

import { useTranslation } from 'react-i18next';

import CloseSVG from 'common/svg/close.svg';
import TrueFillCheckMarkSvg from 'common/svg/true-fill-check-mark.svg';
import FalseFillCheckMarkSvg from 'common/svg/false-fill-check-mark.svg';

import { IFailedTariffId } from '@EZenkof/common-recruiter-library';

import { tariffs } from 'common/helper/consts/tariffs';

import styles from './styles.module.scss';
import PaymentErrorInfoComponent from './components/payment-error-info/payment-error-info.component';
import PaymentErrorButtonsComponent from './components/payment-error-buttons/payment-error-buttons.component';

interface Props {
  handleIsActive: () => void;

  failedTariffId: IFailedTariffId;

  workspaceIdParam?: string;
}

const PaymentErrorComponent = ({ handleIsActive, failedTariffId, workspaceIdParam }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={handleIsActive}>
          <CloseSVG />
        </button>
      </div>

      <div className={styles.content}>
        <PaymentErrorInfoComponent failedTariffId={failedTariffId} />

        <div className={styles.card}>
          <div className={styles.header}>
            <h1>{t('order.inactive', { limits: tariffs[failedTariffId.tariffId].limits })}</h1>
            <p>{t('order.dueTo')}</p>
          </div>
          <ul>
            <li>
              <FalseFillCheckMarkSvg />
              <p>{t('order.tip1')}</p>
            </li>
            <li>
              <FalseFillCheckMarkSvg />
              <p>{t('order.tip2')}</p>
            </li>
            <li>
              <FalseFillCheckMarkSvg />
              <p>{t('order.tip3')}</p>
            </li>
            <li>
              <TrueFillCheckMarkSvg />
              <p>{t('order.tip4')}</p>
            </li>
          </ul>

          <PaymentErrorButtonsComponent
            failedTariffId={failedTariffId}
            handleIsActive={handleIsActive}
            workspaceIdParam={workspaceIdParam}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentErrorComponent;
