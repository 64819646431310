import React, { createContext, useContext, useMemo, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLogout, IUser } from '@EZenkof/common-recruiter-library';

import { useLocalStorage } from './use-local-storage.hook';

// Определяем тип контекста аутентификации
interface AuthContextType {
  user: IUser | null;
  login: (data: IUser) => void;
  logout: () => void;
  refresh: (data: IUser | null, workspaceIdParam?: string) => void;
  redirectToValidWorkspace: (data: IUser | null, workspaceIdParam?: string) => void;
}

// Создаем контекст аутентификации
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Создаем провайдер аутентификации
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useLocalStorage<IUser | null>('canbanUser', null);

  // Функция для входа пользователя
  const login = async (data: IUser) => {
    setUser(data);
    redirectToValidWorkspace(data);
  };

  // Функция для выхода пользователя
  const logout = () => {
    AuthLogout();

    setUser(null);
    localStorage.removeItem('sessionsStatistic');

    navigate('/login', { replace: true });
  };

  const refresh = (data: IUser | null, workspaceIdParam?: string) => {
    setUser(data);

    redirectToValidWorkspace(data, workspaceIdParam);
  };

  const redirectToValidWorkspace = (data: IUser | null, workspaceIdParam?: string) => {
    if (!data) {
      logout();
      return;
    }

    if (data.userToWorkspaces?.length) {
      const isAvailableWorkspaceIdInParam = data.userToWorkspaces.find(
        (userToWorkspace) => userToWorkspace.workspaceId === Number(workspaceIdParam),
      );

      if (!workspaceIdParam || !isAvailableWorkspaceIdInParam)
        navigate(`/profile/${data.userToWorkspaces[0].workspaceId}/candidates`);
    }
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      refresh,
      redirectToValidWorkspace,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
