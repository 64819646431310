import { IUserSearchHistory } from '@EZenkof/common-recruiter-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  filterUserSearchHistoriesReducer,
  filterUserSearchHistoriesReducerFulfilled,
  filterUserSearchHistoriesReducerPending, filterUserSearchHistoriesReducerRejected,
} from './reducers/filter-user-search-histories.reducer';
import {
  filterUserSearchHistoriesCountReducer,
  filterUserSearchHistoriesCountReducerFulfilled,
  filterUserSearchHistoriesCountReducerPending, filterUserSearchHistoriesCountReducerRejected,
} from './reducers/filter-user-search-histories-count.reducer';
import {
  createUserSearchHistoryReducer,
  createUserSearchHistoryReducerFulfilled,
  createUserSearchHistoryReducerPending, createUserSearchHistoryReducerRejected,
} from './reducers/create-user-search-history.reducer';

export interface IUserSearchHistorySlice {
  searchHistories: IUserSearchHistory[];
  count: number;
  page: number;
  isSearchHistoryLoading: boolean;
  isCountLoading: boolean;
  isSearched: boolean;
}

const initialState: IUserSearchHistorySlice = {
  searchHistories: [],
  count: 0,
  page: 0,
  isSearchHistoryLoading: false,
  isCountLoading: false,
  isSearched: false,
};

const UserSearchHistorySlice = createSlice({
  name: 'userSearchHistorySlice',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetSearchHistory: (state) => {
      state.searchHistories = [];
      state.count = 0;
      state.page = 0;
    },
    setIsSearched(state, action: PayloadAction<boolean>) {
      state.isSearched = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(filterUserSearchHistoriesReducer.pending, filterUserSearchHistoriesReducerPending)
      .addCase(filterUserSearchHistoriesReducer.fulfilled, filterUserSearchHistoriesReducerFulfilled)
      .addCase(filterUserSearchHistoriesReducer.rejected, filterUserSearchHistoriesReducerRejected)

      .addCase(filterUserSearchHistoriesCountReducer.pending, filterUserSearchHistoriesCountReducerPending)
      .addCase(filterUserSearchHistoriesCountReducer.fulfilled, filterUserSearchHistoriesCountReducerFulfilled)
      .addCase(filterUserSearchHistoriesCountReducer.rejected, filterUserSearchHistoriesCountReducerRejected)

      .addCase(createUserSearchHistoryReducer.pending, createUserSearchHistoryReducerPending)
      .addCase(createUserSearchHistoryReducer.fulfilled, createUserSearchHistoryReducerFulfilled)
      .addCase(createUserSearchHistoryReducer.rejected, createUserSearchHistoryReducerRejected);
  },
});

export const {setPage, resetSearchHistory, setIsSearched} = UserSearchHistorySlice.actions;

export default UserSearchHistorySlice;
