import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateUserSearchHistory } from '@EZenkof/common-recruiter-library';

import { IUserSearchHistorySlice } from '../user-search-history.slice';

interface ICreate {
  searchString: string;
}

export const createUserSearchHistoryReducer = createAsyncThunk<void, ICreate>(
  'candidatesSlice/createUserSearchHistoryReducer',
  async ({searchString}, {rejectWithValue}) => {
    try{
      const params = new URLSearchParams(searchString);

      params.delete('orKeywords');
      params.delete('orSkills');

      const filtersString = params.toString();

      if (filtersString.length) await CreateUserSearchHistory({filters: filtersString});
    }catch (error: any){
      return rejectWithValue(error.message);
    }
  },
);

export const createUserSearchHistoryReducerPending = (state: IUserSearchHistorySlice) => {};

export const createUserSearchHistoryReducerFulfilled = (state: IUserSearchHistorySlice) => {};

export const createUserSearchHistoryReducerRejected = (state: IUserSearchHistorySlice) => {};

