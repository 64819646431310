import React from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { getWorkspacePath } from 'common/helper/utils/get-workspace-path.util';

import { useAuth } from 'common/helper/hooks/useAuth';

import styles from './styles.module.scss';

interface IPage {
  name: string;
  id: number;
  path: string;
  target?: string;
  needAuth: boolean;
}

const pages = (t: any, workspaceIdParam?: string): IPage[] => [
  {
    name: t('header.search'),
    id: 1,
    path: `/profile${getWorkspacePath(workspaceIdParam)}/candidates`,
    needAuth: true,
  },
  {
    name: t('header.favorites'),
    id: 2,
    path: `/profile${getWorkspacePath(workspaceIdParam)}/favorites`,
    needAuth: true,
  },
  {
    name: t('header.viewed'),
    id: 3,
    path: `/profile${getWorkspacePath(workspaceIdParam)}/viewed`,
    needAuth: true,
  },
  {
    name: t('header.history'),
    id: 4,
    path: `/profile${getWorkspacePath(workspaceIdParam)}/history`,
    needAuth: true,
  },
  {
    name: 'Обзор',
    id: 5,
    path: '/',
    needAuth: false,
  },
  //todo временно
  // {
  //   //   name: 'Тарифы',
  //   //   id: 3,
  //   //   path: '/pricing',
  //   //   needAuth: false,
  //   // },
  //   // {
  //   //   name: 'Wiki',
  //   //   id: 4,
  //   //   path: `${process.env.REACT_APP_WIKI_URL}`,
  //   //   target: '_blank',
  //   //   needAuth: false,
  // },
  {
    name: 'Войти',
    id: 6,
    path: '/login',
    needAuth: false,
  },
];

interface Props {
  workspaceIdParam?: string;
}

const HeaderPagesComponent = ({workspaceIdParam}: Props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();

  const profileListStyle = location.pathname.includes('profile')
    ? { justifyContent: 'center' }
    : { paddingRight: '15px' };

  return (
    <>
      <NavLink to="/" className={`${styles.headerItem} ${styles.logo}`}>
        <p>Hyranse</p>
      </NavLink>

      <div className={styles.container} style={profileListStyle}>
        <ul className={styles.navigation}>{
          pages(
            t,
            workspaceIdParam ?? String(user?.userToWorkspaces?.[0]?.workspaceId),
          )
            .filter((page) => {
              if (location.pathname.includes('profile'))
                return !page.needAuth ? null : page;
              else {
                return !page.needAuth ? page : null;
              }
            })
            .map((page) => (
              <li key={page.id}>
                <NavLink className={`${styles.headerItem}`} to={page.path} target={page.target}>
                  <div className={styles.expand}>
                    <p>{page.name}</p>
                  </div>
                </NavLink>

                {location.pathname === page.path && <span className={styles.active__border}></span>}
              </li>
            ))
        }</ul>
      </div>
    </>
  );
};

export default HeaderPagesComponent;
