import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFailedTariffId, ResumeSubscription, StartSubscription } from '@EZenkof/common-recruiter-library';
import { toast } from 'react-toastify';

import { useAppDispatch } from 'store/interface/store.interface';
import {
  getAvailableParseLimitReducer,
} from 'store/slices/available-parse-limit/reducers/get-available-parse-limit.reducer';
import { getPaymentInfoReducer } from 'store/slices/payments/reducers/get-payment-info.reducer';

import Button from 'common/components/button/button.component';

import styles from './styles.module.scss';

interface Props {
  handleIsActive: () => void;

  failedTariffId: IFailedTariffId;

  workspaceIdParam?: string;
}

const PaymentErrorButtonsComponent = ({ failedTariffId, handleIsActive, workspaceIdParam }: Props) => {
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handlePayAnotherTime = () => {
    const workspaceId = Number(workspaceIdParam);

    if (!workspaceId) return;

    setIsPaymentLoading(true);

    ResumeSubscription({ tariffId: failedTariffId.tariffId, workspaceId })
      .then((res) => {
        if (typeof res === 'string') {
          window.open(res, '_blank', 'noopener,noreferrer');
          handleIsActive();
        } else {
          if (res) {
            toast.success(t('payments.resumeSubscriptionModal.resume.success'));
            handleIsActive();
          } else toast.error(t('common.paymentError'));
        }
      })
      .finally(() => {
        setIsPaymentLoading(false);

        Promise.all([
          dispatch(getAvailableParseLimitReducer(workspaceId)),
          dispatch(getPaymentInfoReducer(workspaceId)),
        ]);
      });
  };

  const handlePayWithAnotherCard = () => {
    const workspaceId = Number(workspaceIdParam);

    if (!workspaceId) return;

    return StartSubscription({ tariffId: failedTariffId.tariffId, workspaceId })
      .then((res) => {
        if (res) window.open(res, '_blank', 'noopener,noreferrer');
        handleIsActive();
      })
      .catch(() => {
        toast.error(t('common.paymentError'));
      });
  };

  return (
    <div className={styles.container}>
      {failedTariffId.reason === 'paymentError' && (
        <Button
          size="none"
          className={styles.button}
          onClick={handlePayAnotherTime}
          isLoading={isPaymentLoading}
          disabled={isPaymentLoading}>
          <p>{t('buttons.tryAgain')}</p>
        </Button>
      )}
      <Button
        size="none"
        className={styles.button}
        onClick={handlePayWithAnotherCard}
        isLoading={isPaymentLoading}
        disabled={isPaymentLoading}>
        <p>{t('buttons.addNewCard')}</p>
      </Button>
    </div>
  );
};

export default PaymentErrorButtonsComponent;
