import { createSlice } from '@reduxjs/toolkit';

import {
  getAvailableParseLimitReducer,
  getAvailableParseLimitReducerFulfilled,
  getAvailableParseLimitReducerPending,
  getAvailableParseLimitReducerRejected,
} from './reducers/get-available-parse-limit.reducer';

export interface IAvailableParseLimitSlice {
  limit: number | null;
  isLoading: boolean;
}

const initialState: IAvailableParseLimitSlice = {
  limit: null,
  isLoading: false,
};

const AvailableParseLimitSlice = createSlice({
  name: 'availableParseLimit',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAvailableParseLimitReducer.pending, getAvailableParseLimitReducerPending)
      .addCase(getAvailableParseLimitReducer.fulfilled, getAvailableParseLimitReducerFulfilled)
      .addCase(getAvailableParseLimitReducer.rejected, getAvailableParseLimitReducerRejected);
  },
});

export default AvailableParseLimitSlice;
