import {
  GetUserToFavoriteCandidatesCount,
  IFilterUserToFavoriteCandidatesCountDto,
} from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { ICandidatesSlice } from '../candidates.slice';

interface IFilter {
  data: IFilterUserToFavoriteCandidatesCountDto;
}

interface IFilterResult {
  count: number;
}

export const filterFavoritesCandidatesCountReducer = createAsyncThunk<IFilterResult, IFilter>(
  'candidatesSlice/filterFavoritesCandidatesCountReducer',
  async ({data}, {rejectWithValue}) => {
    try {
      const count = await GetUserToFavoriteCandidatesCount(data);

      return {
        count: count ?? 0,
      };
    }catch (error: any){
      return rejectWithValue(error.message);
    }
  },
);

export const filterFavoritesCandidatesCountReducerPending = (state: ICandidatesSlice) => {
  state.isCountLoading = true;
};

export const filterFavoritesCandidatesCountReducerFulfilled = (state: ICandidatesSlice, action: PayloadAction<IFilterResult>) => {
  state.count = action.payload.count;

  state.isCountLoading = false;
};

export const filterFavoritesCandidatesCountReducerRejected = (state: ICandidatesSlice) => {
  state.isCountLoading = false;
};

