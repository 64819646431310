export const ROUTES = {
  INDEX: {
    INDEX: '/',
    PRICING: '/pricing',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    INVITE: '/registration/:inviteToken',
    ACTIVATE: '/activate',
    RECOVERY: '/recovery',
    ALL: '/*',
  },

  PROFILE: {
    INDEX: '/profile',
    CANDIDATES: ':workspaceId/candidates',
    FAVORITES: ':workspaceId/favorites',
    VIEWED: ':workspaceId/viewed',
    HISTORY: ':workspaceId/history',
    PAYMENTS: ':workspaceId/payments',
    WORKSPACE: ':workspaceId/workspace',
  },
};
