import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useLocalStorage } from 'common/helper/hooks/use-local-storage.hook';
import { getPaymentInfoReducer } from 'store/slices/payments/reducers/get-payment-info.reducer';
import { useAppDispatch, useAppSelector } from 'store/interface/store.interface';
import { getAvailableParseLimitReducer } from 'store/slices/available-parse-limit/reducers/get-available-parse-limit.reducer';

import Modal from 'common/components/modal/modal.component';

import { EUserToWorkspaceRole } from '@EZenkof/common-recruiter-library';

import { findWorkspaceInUser } from 'common/helper/utils/find-workspace-in-user.util';
import { useAuth } from 'common/helper/hooks/useAuth';

import PaymentErrorComponent from './components/payment-error/payment-error.component';

interface Props {
  workspaceIdParam?: string;
}

const InfoLayer = ({workspaceIdParam}: Props) => {
  const [sessionsStatistic, setSessionsStatistic] = useLocalStorage<{
    isShowedTrialEndModal?: boolean;
    isShowedPaymentErrorModal?: boolean;
  }>('sessionsStatistic', {
    isShowedTrialEndModal: false,
    isShowedPaymentErrorModal: false,
  });

  const dispatch = useAppDispatch();
  const {user} = useAuth();
  const location = useLocation();

  const {
    info: { failedTariffId },
  } = useAppSelector((state) => state.payments);

  const handleClosePaymentErrorModal = () => setSessionsStatistic({
    ...sessionsStatistic,
    isShowedPaymentErrorModal: true,
  });

  const isUserAdminInWorkspace = () =>
    findWorkspaceInUser(user, workspaceIdParam)?.role === EUserToWorkspaceRole.ADMIN;

  useEffect(() => {
    const workspaceId = Number(workspaceIdParam);

    if (workspaceId) {
      dispatch(getPaymentInfoReducer(workspaceId));
      dispatch(getAvailableParseLimitReducer(workspaceId));
    }
  }, [location.pathname, workspaceIdParam]);

  if (failedTariffId && !sessionsStatistic?.isShowedPaymentErrorModal && isUserAdminInWorkspace())
    return (
      <Modal active={true} setActive={handleClosePaymentErrorModal}>
        <PaymentErrorComponent
          failedTariffId={failedTariffId}
          handleIsActive={handleClosePaymentErrorModal}
          workspaceIdParam={workspaceIdParam}
        />
      </Modal>
    );
  else return null;
};

export default InfoLayer;
