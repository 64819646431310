import { GetCandidatesCount, IFilterCandidatesDto } from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { ICandidatesSlice } from '../candidates.slice';

interface IFilter {
  data: IFilterCandidatesDto;
}

interface IFilterResult {
  count: number;
}

export const filterCandidatesCountReducer = createAsyncThunk<IFilterResult, IFilter>(
  'candidatesSlice/filterCandidatesCountReducer',
  async ({ data }, { rejectWithValue }) => {
    try {
      const count = await GetCandidatesCount(data);

      return {
        count: count ?? 0,
      };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterCandidatesCountReducerPending = (state: ICandidatesSlice) => {
  state.isCountLoading = true;
};

export const filterCandidatesCountReducerFulfilled = (state: ICandidatesSlice, action: PayloadAction<IFilterResult>) => {
  state.count = action.payload.count;

  state.isCountLoading = false;
};

export const filterCandidatesCountReducerRejected = (state: ICandidatesSlice) => {
  state.isCountLoading = false;
};
