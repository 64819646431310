import { ICandidate } from '@EZenkof/common-recruiter-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICandidateModalSlice {
  active: boolean;
  candidate?: ICandidate;
  candidateIndex?: number;
}

const initialState: ICandidateModalSlice = {
  active: false,
};

const CandidateModalSlice = createSlice({
  name: 'candidateModalSlice',
  initialState,
  reducers: {
    handleCandidateModal(state, action: PayloadAction<Partial<ICandidateModalSlice>>){
      const {candidate, candidateIndex} = action.payload;

      if (candidate && typeof candidateIndex === 'number'){
        state.candidate = candidate;
        state.candidateIndex = candidateIndex;
        state.active = true;
      } else state.active = false;
    },
    onUpdateModalCandidateFavoriteStatus(state, action: PayloadAction<{isFavorite: boolean}>){
      if (state.candidate)
        state.candidate.isFavorite = action.payload.isFavorite;
    },
  },
});

export const {handleCandidateModal, onUpdateModalCandidateFavoriteStatus} = CandidateModalSlice.actions;

export default CandidateModalSlice;
