import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import 'index.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from 'App';
import { BrowserRouter } from 'react-router-dom';
import { ETelegramUsers, instance, sendTelegramMessages } from '@EZenkof/common-recruiter-library';
import { toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { I18nextProvider } from 'react-i18next';
import mixpanel from 'mixpanel-browser';

import { backendExceptionsToSkip } from 'common/helper/consts/backend-exceptions-to-skip';

import i18n from './i18n';

const mixpaneltoken = process.env.REACT_APP_MIXPANEL_TOKEN || '3985e1949e8351177b888119bd48e66f';

mixpanel.init(mixpaneltoken, {
  debug: true,
  persistence: 'localStorage',
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

instance.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

instance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (!backendExceptionsToSkip.some(
      (expectedExceptionText) => expectedExceptionText === error.response?.data?.message ?? '',
    )) {
      toast.error(error.response?.data?.message ?? error.message);
      
      const messages = [];

      if (error.message) messages.push(`Message: ${error.message}`);
      if (error.response?.data?.message) messages.push(`Backend Message: ${error.response.data.message}`);
      if (error.response?.status) messages.push(`Status: ${error.response.status}`);
      if (error.config?.url) messages.push(`URL: ${error.config.url}`);
      if (error.config?.method) messages.push(`Method: ${error.config.method}`);
      if (error.config?.data) messages.push(`Request Data: ${error.config.data}`);
      if (error.stack) messages.push(`Stack:\n${error.stack}`);
      messages.push(`Client Online: ${navigator.onLine}`);
      
      if (process.env.REACT_APP_IS_PROD) 
        sendTelegramMessages(
          [ETelegramUsers.ARTEM, ETelegramUsers.EUGENE], 
          `[FRONTEND] [PROD]\n\n${messages.join('\n\n')}`,
        );
    }
  },
);

instance.interceptors.request.use(
  (config: any) => {
    const user = JSON.parse(localStorage.getItem('canbanUser') || '{}');

    config.withCredentials = true;

    if (user && user.accessToken && user.refreshToken)
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${user.accessToken}`,
        'X-Refresh-Token': user.refreshToken,
      };

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

window.addEventListener('error', function (error: any) {
  if (error?.message?.includes('Loading CSS chunk')) {
    if (process.env.REACT_APP_IS_PROD)
      sendTelegramMessages(
        [ETelegramUsers.ARTEM, ETelegramUsers.EUGENE],
        '[FRONTEND] [PROD]\n\nChunk loading failed, reloading...',
      );

    window.location.reload();
  }
});
