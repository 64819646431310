import { EViewedCandidateCardStatus, MarkUserToCandidates } from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { ICandidatesSlice } from '../candidates.slice';

interface IUpdate {
  candidateIds: string[];
  viewedCardStatus: EViewedCandidateCardStatus;
}

interface IUpdateResult {
  candidateIds: string[];
  viewedCardStatus: EViewedCandidateCardStatus;
}

export const updateCandidateViewCardStatusReducer = createAsyncThunk<IUpdateResult, IUpdate>(
  'candidatesSlice/updateCandidateViewCardStatusReducer',
  async ({ candidateIds, viewedCardStatus}, {rejectWithValue}) => {
    try {
      await MarkUserToCandidates({candidateIds, viewedCandidateCardStatus: viewedCardStatus});

      return {
        candidateIds,
        viewedCardStatus,
      };
    }catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateCandidateViewCardStatusReducerPending = (state: ICandidatesSlice) => {};

export const updateCandidateViewCardStatusReducerFulfilled = (state: ICandidatesSlice, action: PayloadAction<IUpdateResult>) => {
  state.candidates = state.candidates.map((candidate) => 
    action.payload.candidateIds.includes(candidate.id)
      ? {...candidate, viewedCardStatus: action.payload.viewedCardStatus}
      : candidate,
  );
};

export const updateCandidateViewCardStatusReducerRejected = (state: ICandidatesSlice) => {};
