import React from 'react';

interface Props {
  title?: string;
}

const CloseSVG = ({title}: Props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
      {title?.length && <title>{title}</title>}
      <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
    </svg>
  );
};

export default CloseSVG;
