import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { GetUserToCandidatesCount, IFilterUserToCandidatesCountDto } from '@EZenkof/common-recruiter-library';

import { ICandidatesSlice } from '../candidates.slice';

interface IFilter {
  data: IFilterUserToCandidatesCountDto;
}

interface IFilterResult {
  count: number;
}

export const filterViewedCandidatesCountReducer = createAsyncThunk<IFilterResult, IFilter>(
  'candidatesSlice/filterViewedCandidatesCountReducer',
  async ({data}, {rejectWithValue}) => {
    try {
      const count = await GetUserToCandidatesCount(data);

      return {
        count: count ?? 0,
      };
    }catch (error: any){
      return rejectWithValue(error.message);
    }
  },
);

export const filterViewedCandidatesCountReducerPending = (state: ICandidatesSlice) => {
  state.isCountLoading = true;
};

export const filterViewedCandidatesCountReducerFulfilled = (state: ICandidatesSlice, action: PayloadAction<IFilterResult>) => {
  state.count = action.payload.count;

  state.isCountLoading = false;
};

export const filterViewedCandidatesCountReducerRejected = (state: ICandidatesSlice) => {
  state.isCountLoading = false;
};

