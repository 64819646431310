import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetUserSearchHistories,
  IFilterUserSearchHistoriesDto,
  IUserSearchHistory,
} from '@EZenkof/common-recruiter-library';

import { IUserSearchHistorySlice } from '../user-search-history.slice';

interface IFilter {
  data: IFilterUserSearchHistoriesDto;
}

interface IFilterResult {
  searchHistories: IUserSearchHistory[];
}

export const filterUserSearchHistoriesReducer = createAsyncThunk<IFilterResult, IFilter>(
  'userSearchHistorySlice/filterUserSearchHistoriesReducer',
  async ({ data }, { rejectWithValue }) => {
    try {
      const searchHistories = await GetUserSearchHistories(data);

      return {
        searchHistories: searchHistories ?? [],
      };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterUserSearchHistoriesReducerPending = (state: IUserSearchHistorySlice) => {
  state.isSearchHistoryLoading = true;
};

export const filterUserSearchHistoriesReducerFulfilled = (state: IUserSearchHistorySlice, action: PayloadAction<IFilterResult>) => {
  state.searchHistories = action.payload.searchHistories;

  state.isSearchHistoryLoading = false;
};

export const filterUserSearchHistoriesReducerRejected = (state: IUserSearchHistorySlice) => {
  state.isSearchHistoryLoading = false;
};
