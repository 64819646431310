import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { GetUserToCandidates, ICandidate, IFilterUserToCandidatesDto } from '@EZenkof/common-recruiter-library';

import { ICandidatesSlice } from '../candidates.slice';

interface IFilter {
  data: IFilterUserToCandidatesDto;
}

interface IFilterResult {
  candidates: ICandidate[];
}

export const filterViewedCandidatesReducer = createAsyncThunk<IFilterResult, IFilter>(
  'candidatesSlice/filterViewedCandidatesReducer',
  async ({data}, {rejectWithValue}) => {
    try{
      const candidates = await GetUserToCandidates(data);

      return {
        candidates: candidates ?? [],
      };
    }catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterViewedCandidatesReducerPending = (state: ICandidatesSlice) => {
  state.isCandidatesLoading = true;
};

export const filterViewedCandidatesReducerFulfilled = (state: ICandidatesSlice, action: PayloadAction<IFilterResult>) => {
  state.candidates = action.payload.candidates;

  state.isCandidatesLoading = false;
};

export const filterViewedCandidatesReducerRejected = (state: ICandidatesSlice) => {
  state.isCandidatesLoading = false;
};
