import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetCandidates,
  ICandidate,
  IFilterCandidatesDto,
} from '@EZenkof/common-recruiter-library';

import { ICandidatesSlice } from '../candidates.slice';

interface IFilter {
  data: IFilterCandidatesDto;
}

interface IFilterResult {
  candidates: ICandidate[];
}

export const filterCandidatesReducer = createAsyncThunk<IFilterResult, IFilter>(
  'candidatesSlice/filterCandidatesReducer',
  async ({ data }, { rejectWithValue }) => {
    try {
      const candidates = await GetCandidates(data);

      return {
        candidates: candidates ?? [],
      };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterCandidatesReducerPending = (state: ICandidatesSlice) => {
  state.isCandidatesLoading = true;
};

export const filterCandidatesReducerFulfilled = (state: ICandidatesSlice, action: PayloadAction<IFilterResult>) => {
  state.candidates = action.payload.candidates;

  state.isCandidatesLoading = false;
};

export const filterCandidatesReducerRejected = (state: ICandidatesSlice) => {
  state.isCandidatesLoading = false;
};
