import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CreateUserToFavoriteCandidate, DeleteUserToFavoriteCandidate } from '@EZenkof/common-recruiter-library';

import { ICandidatesSlice } from '../candidates.slice';

interface IUpdate {
  candidateId: string;
  isFavorite: boolean;
}

interface IUpdateResult {
  candidateId: string;
  isFavorite: boolean;
}

export const updateCandidateFavoriteStatusReducer = createAsyncThunk<IUpdateResult, IUpdate>(
  'candidatesSlice/updateCandidateFavoriteStatusReducer',
  async ({candidateId, isFavorite}, {rejectWithValue}) => {
    try{
      isFavorite
        ? await CreateUserToFavoriteCandidate(candidateId)
        : await DeleteUserToFavoriteCandidate(candidateId); 
      
      return {candidateId, isFavorite};
    }catch(error: any){
      return rejectWithValue(error.message);
    }
  },
);

export const updateCandidateFavoriteStatusReducerPending = (state: ICandidatesSlice) => {};

export const updateCandidateFavoriteStatusReducerFulfilled = (state: ICandidatesSlice, action: PayloadAction<IUpdateResult>) => {
  state.candidates = state.candidates.map((candidate) =>
    action.payload.candidateId === candidate.id
      ? {...candidate, isFavorite: action.payload.isFavorite}
      : candidate,
  );
};

export const updateCandidateFavoriteStatusReducerRejected = (state: ICandidatesSlice) => {};

